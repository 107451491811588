import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'

import gm from '../images/GM_ikon.png'

// http://www.dktech.cz/?lang=cz&paged=11
// http://www.dktech.cz/?p=161

const PostVyberoveRizeni: FC = () => {
  return (
    <PageTemplate
      prevLink="/post_novi_gamemasters"
      nextLink="/post_hlasovani_stehovani_serveru"
    >
      <PostHeader
        title="Výběrové řízení na nové GM"
        link="/post_vyberove_rizeni"
        author="Algy"
        date="20.02.2014"
        time="9:59"
        category="Oznámení"
      />
      <h3>10. března 2014</h3>
      <h4 style={{ textAlign: 'justify' }}>Dne 9. března 2014 bylo výběrové řízení na nová GM ukončeno. Celkem se přihlásilo 24 uchazečů, další 2 podali přihlášku v době, kdy již probíhalo hodnocení. Se třemi nejúspěšnějšími započnou již tento týden kroky k jejich uvedení do hry. Další 4 uchazeči byli vybráni jako úspěšní pro doplňovací kolo. Všem přihlášeným děkujeme za zájem a vybraným přejeme, aby v nových pozicích našli nejen své uplatnění, ale i pochopení herní komunity a hodiny naplněné zábavou.</h4>
      <h3>8. března 2014</h3>
      <h4>Příjem přihlášek na pozici GM byl ukončen. Nyní probíhá výběrové řízení.</h4>
      <p>Zdravím,</p>
      <p style={{ textAlign: 'justify' }}>&nbsp; &nbsp; &nbsp;rád bych Vás informoval, že v tomto týdnu budou s největší pravděpodobností ukončeny práce na nové verzi pravidel pro náš server. Máme v úmyslu umožnit hráčům před jejich vyhlášením uplatnit do jejich znění připomínky. To je ale pouze jeden z kroků, které jsou třeba na dlouhé cestě ke kvalitnímu serveru udělat. Tím dalším, který musí bezprostředně následovat, je doplnění týmu lidí ve vedení serveru. Je málo těch, kteří dohlíží na dodržování pravidel. Je málo těch, kteří se starají o hráče a o jejich zábavu. Proto jsme se rozhodli dnešním dnem vypsat</p>
      <p style={{ textAlign: 'justify' }}><a href={gm}><img className="alignleft size-medium wp-image-168" alt="GM_ikon" src={gm} width="1" height="1" /></a></p>
      <h2 style={{ textAlign: 'center' }}><a href={gm}><img className="alignright size-medium wp-image-168" alt="GM_ikon" src={gm} width="1" height="1" /></a><img className="aligncenter size-medium wp-image-168" alt="GM_ikon" src={gm} width="1" height="1" />&nbsp;Výběrové řízení na nové GM&nbsp;<a href={gm}><img className="aligncenter size-medium wp-image-168" alt="GM_ikon" src={gm} width="1" height="1" /></a></h2>
      <p style={{ textAlign: 'justify' }}>&nbsp; &nbsp; &nbsp;Hledáme zatím blíže neurčený počet nových GM. Nově však nechceme předem rozdělovat pozici na provozní GM a event GM. Chceme dát každému novému GM prostor vyprofilovat se do&nbsp;oblasti, která mu půjde nejlépe.</p>
      <p style={{ textAlign: 'justify' }}><span id="more-161"></span></p>
      <p><strong>Náplň činnosti GM:</strong></p>
      <p>- dohled nad chodem hry (v případě pádu vyrozumění adminů),</p>
      <p>- dohled nad dodržováním pravidel hry hráči (včetně řešení lehčích prohřešků),</p>
      <p>- dokumentace těžších prohřešků a jejich předání k řešení hlavnímu GM / adminovi,</p>
      <p>- pomoc a rady hráčům v souladu s Kodexem GM,</p>
      <p>- sbírání a předávání hlášení o chybách ve hře adminům / hlavnímu GM,</p>
      <p>- možnost pořádání svateb a jednodušších eventů,</p>
      <p>-&nbsp;tvorba a&nbsp;pořádání jednodušších i dlouhodobějších eventů.</p>
      <p><strong>Požadavky:</strong></p>
      <p>- bezpodmínečné dodržování&nbsp;GM Kodexu,</p>
      <p>-&nbsp;znalost pravidel serveru z pohledu hráče,</p>
      <p>- věk 18+ (není nutností, ale výhodou),</p>
      <p>- morální a trestní bezúhonnost na GP L2 (i DKT) – tzn. nikdo kdo tu byl jakkoliv trestán,</p>
      <p>- znalost anglického jazyka (rozumět a dokázat v angličtině komunikovat),</p>
      <p>- dobrá znalost světa L2 (rasy, povolání, skilly, itemy, questy…),</p>
      <p>- schopnost jednání s lidmi (i ve složitých situacích a pod tlakem zůstávat klidný a především slušný),</p>
      <p>- schopnost jednat samostatně, ale i v kooperaci s ostatními členy týmu (hlavní GM/admini),</p>
      <p>- ochotu věnovat se GMkování/moderování alespoň pár hodin denně/týdně,</p>
      <p>- znalost GM příkazů není podstatná, ty naučíme za chodu,</p>
      <p>- pokud chcete být GM jen proto, že se ve hře nudíte, nebo chcete machrovat před kamarády tak se prosím ani nehlaste!</p>
      <p style={{ textAlign: 'justify' }}>&nbsp; &nbsp; &nbsp;Zájemci nechť zašlou své přihlášky ve formě mailu na adresu&nbsp;<span style={{ color: '#0000ff' }}>gpl2help@gmail.com</span>&nbsp;s předmětem&nbsp;<span style={{ textDecoration: 'underline' }}>GM – výběrové řízení</span>&nbsp;(maily s jiným předmětem nebudou brány v potaz). Obsahem by mělo být uvedení důvodu, proč chcete být GM, co do hry přinesete a nakolik vyhovujete kladeným požadavkům.&nbsp; Taktéž je nutné pravdivě uvést všechny herní postavy, které na serveru máte (pokud zde hrajete). Náš tým bude průběžně vybírat případné vhodné kandidáty a bude je oslovovat. A to až do doby, než dojdeme k&nbsp;závěru, že GM tým byl dostatečně doplněn. Vyhrazujeme si právo nevybrat nikoho, pokud nesplní námi kladené požadavky.</p>
      <p style={{ textAlign: 'justify' }}>&nbsp; &nbsp; &nbsp;Dnešním dnem počínaje se tedy může stát, že budete ve hře potkávat nová GM. Buďte k&nbsp;nim slušní a shovívaví. Nepochybuji o tom, že vám budou stejnou mincí oplácet.</p>
      <p style={{ textAlign: 'justify' }}>&nbsp; &nbsp; &nbsp;O ukončení výběrového řízení a o tom, koho jsme do požadovaných pozic vybrali, vás budeme informovat.</p>
      <p style={{ textAlign: 'right' }}>Za vedení serveru GM Algy</p>
      <div className="cleared"></div>
      <span className="linkpages"></span><div className="cleared"></div>

      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default PostVyberoveRizeni

export { Head } from '../components/defaultHtmlHead'